import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";

const WinesFromAnotherWorld = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Wines From Another World"
      description="Exclusive wine collection where each planet represents one
      wine."
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Wines from another world</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Development" />
                  <ServicePellet service="Branding" />
                  <Row className="my-md-4 my-1 d-md-block d-none">
                    <p class="wow move-up mb--20">
                      Exclusive wine collection where each planet represents one
                      wine. Once a year a wine will be released and with each
                      bottle comes an code that gives access to the website and
                      it’s exclusive content. An intergalactic wine collection
                      and experience.
                    </p>
                  </Row>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[10]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-md-none d-block">
              <p class="wow move-up mb--20">
                Martins wine advisor are experts in wine consultancy.
              </p>
              <p class="wow move-up mb--20">
                Experts promoting new products, penetrating emerging markets and
                providing specialist advice to clients from corporate
                organizations, private individuals to royal families; focused on
                securing the right product at the right price for any occasion.
              </p>
              <p>
                Won the pize for
                <strong>Best Wine Advisory Service - UK 2019</strong>
              </p>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12}>
                <Img fluid={imagesData[0]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4" style={{ maxWidth: 840, margin: "auto" }}>
              <Col xs={12}>
                <Img fluid={imagesData[1]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4" style={{ maxWidth: 840, margin: "auto" }}>
              <Col xs={12}>
                <Img fluid={imagesData[2]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4" style={{ maxWidth: 840, margin: "auto" }}>
              <Col xs={12}>
                <Img fluid={imagesData[3]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12}>
                <Img fluid={imagesData[4]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={6} xs={12} className="p-2">
                <Img fluid={imagesData[5]} />
              </Col>
              <Col md={6} xs={12} className="p-2">
                <Img fluid={imagesData[6]} />
              </Col>
              <Col md={6} xs={12} className="p-2">
                <Img fluid={imagesData[7]} />
              </Col>
              <Col md={6} xs={12} className="p-2">
                <Img fluid={imagesData[8]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4" style={{ maxWidth: 290, margin: "auto" }}>
              <Col xs={12}>
                <Img fluid={imagesData[9]} />
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="wfaw" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Linq", link: "/work/linq" }}
            prevProject={{ name: "Emerald", link: "/work/emerald" }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default WinesFromAnotherWorld;

export const WinesFromAnotherWorldeQuery = graphql`
  query WinesFromAnotherWorldPhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/wfaw/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
